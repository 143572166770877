import BaseIcon from './BaseIcon';

const CancelIcon = ({ className, onClick, color }) => {
  return (
    <BaseIcon className={className} onClick={onClick} color={color}>
      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
      <path d='M18 6l-12 12' />
      <path d='M6 6l12 12' />
    </BaseIcon>
  );
};

export default CancelIcon;
