import BaseIcon from '../BaseIcon';

const ExecutionSiteIcon = ({ className }) => {
  return (
    <BaseIcon className={className}>
      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
      <path d='M12 4c4.418 0 8 3.358 8 7.5c0 1.901 -.755 3.637 -2 4.96l0 2.54a1 1 0 0 1 -1 1h-10a1 1 0 0 1 -1 -1v-2.54c-1.245 -1.322 -2 -3.058 -2 -4.96c0 -4.142 3.582 -7.5 8 -7.5z' />
      <path d='M10 17v3' />
      <path d='M14 17v3' />
      <path d='M9 11m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0' />
      <path d='M15 11m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0' />
    </BaseIcon>
  );
};

export default ExecutionSiteIcon;
