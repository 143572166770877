import BaseIcon from '../BaseIcon';

const VerificationIcon = ({ className }) => {
  return (
    <BaseIcon className={className}>
      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
      <path d='M10 9a2 2 0 1 0 4 0a2 2 0 0 0 -4 0' />
      <path d='M4 8v-2a2 2 0 0 1 2 -2h2' />
      <path d='M4 16v2a2 2 0 0 0 2 2h2' />
      <path d='M16 4h2a2 2 0 0 1 2 2v2' />
      <path d='M16 20h2a2 2 0 0 0 2 -2v-2' />
      <path d='M8 16a2 2 0 0 1 2 -2h4a2 2 0 0 1 2 2' />
    </BaseIcon>
  );
};

export default VerificationIcon;
