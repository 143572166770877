import BaseIcon from './BaseIcon';

const AccountIcon = ({ className }) => {
  return (
    <BaseIcon className={className}>
      <path d='M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0' />
      <path d='M12 10m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0' />
      <path d='M6.168 18.849a4 4 0 0 1 3.832 -2.849h4a4 4 0 0 1 3.834 2.855' />
    </BaseIcon>
  );
};

export default AccountIcon;
